import List from '../List';
import Section, {Subsection} from '../Section';
import RulesJSON from '../../json/rules.json';

const {rules, behavior} = RulesJSON;

const Rules = () => (
    <Section title={'Règlement de la LvlUp Game Jam'} id={"reglement"}>
        <Subsection title={'Règles générales'}/>
        <List points={rules}/>
        <Subsection title={'Comportement'}/>
        <List points={behavior}/>
    </Section>
);

export default Rules;
