import RoutesJSON from "../../json/routes.json";
import {NavHashLink} from 'react-router-hash-link';
import React, {useState} from 'react';
import {FaBars, FaXmark} from 'react-icons/fa6';
import {Link} from "react-router-dom";
import CustomLink from "../CustomLink";

const {home, header} = RoutesJSON;

export const Header = () => (
    <header className="bg-white text-lg xl:text-xl">
        <DesktopMenu/>
        <MobileMenu/>
    </header>
);

const HomeLink = () => {
    return (
        <Link reloadDocument to={home.link} className="h-full flex items-center py-2">
            <img className={'h-full'} src={'./images/logos/lvlup-sm.png'} alt={'Logo LVLUP Game Jam'}/>
        </Link>
    );
}

const DesktopMenu = () => (
    <div className={'hidden 2xl:block'}>
        <nav className={'mx-120 flex h-20 items-center justify-between'}>
            {HomeLink()}
            {Object.values(header).map(link =>
                    <CustomLink title>
                        <NavHashLink smooth to={link.link}>{link.name}</NavHashLink>
                    </CustomLink>
            )}
        </nav>
    </div>
);

const MobileMenu = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () => setIsMobileMenuOpen(prevState => !prevState);

    return (
        <div className={'2xl:hidden'}>
            <div className={'flex justify-between px-12 items-center h-20'}>
                {HomeLink()}
                    <button onClick={toggleMobileMenu} className={'hover:text-blue'}>
                        {isMobileMenuOpen === false ? <FaBars/> : <FaXmark/>}
                    </button>
            </div>
            <nav className={`flex flex-col space-y-4 pl-12 ${isMobileMenuOpen ? 'block' : 'hidden'}`}
                 style={{height: 'calc(100vh)'}}>
                {Object.values(header).map(link =>
                    <CustomLink title>
                        <NavHashLink smooth to={link.link} onClick={() => toggleMobileMenu()}>
                            {link.name}</NavHashLink>
                    </CustomLink>
                )}
            </nav>
        </div>
    );
}

export default Header;