import {NavHashLink} from "react-router-hash-link";
import React, {useEffect, useState} from "react";
import {FaArrowUpLong} from "react-icons/fa6";
import RoutesJSON from "../../json/routes.json";

const CustomButton = ({children, onClick, textSize}) => {
    return (
        <button
            className={
                `m-2 p-1 border border-orange rounded-md text-orange
                active:bg-orange active:text-white
                hover:bg-orange hover:text-white
                text-${textSize || 'base'}`}
            onClick={onClick}>{children}</button>
    )
}

export const BackToTopButton = () => {
    const {home} = RoutesJSON;
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= window.innerHeight) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    if (!isVisible) return null;

    return (
        <button
            className={
                `m-2 p-1 border border-orange rounded-full text-orange
                active:bg-orange active:text-white
                hover:bg-orange hover:text-white
                fixed xl:bottom-4 bottom-0 xl:right-4 right-0`
            }>
            <NavHashLink smooth to={home.link}><FaArrowUpLong size={30}/></NavHashLink>
        </button>
    );
};

export default CustomButton;