import {Route, Routes} from 'react-router-dom';
import Home from '../pages/Home';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RoutesJSON from '../json/routes.json';
import Banner from "../components/Banner";

const Router = () => {

    const {home} = RoutesJSON;

    return (
        <div>
            <div className={'sticky top-0'}>
                <Banner/>
                <Header/>
            </div>
            <Routes>
                <Route path={home.entryPoint} element={<Home/>}/>
            </Routes>
            <Footer/>
        </div>
    );
};

export default Router;