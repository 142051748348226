import Section from '../Section';
import RoutesJSON from '../../json/routes.json';
import CustomLink from "../CustomLink";

const Contact = () => (
    <Section title={'Contactez-nous !'} id={RoutesJSON.header.contact.id}>
        <p>
            Contactez-nous par email ici&nbsp;: {' '}
            <CustomLink link={`mailto:${RoutesJSON.email}`} hoverUnderline>{RoutesJSON.email}</CustomLink>{' '}
            si vous désirez rejoindre la jam en tant que membre du comité, staff ou pour toute autre question.
        </p>
        <p> Alternativement, vous pouvez aussi nous contacter sur notre serveur{' '}
            <CustomLink link={RoutesJSON.social.discord.link} external hoverUnderline>Discord</CustomLink> dans le canal <span className={'italic'}>forum-questions</span>.
        </p>
    </Section>
);

export default Contact;
