import {FaFacebook} from 'react-icons/fa6';
import {FaLinkedin} from 'react-icons/fa6';
import {FaDiscord} from 'react-icons/fa6';
import {FaDownload} from "react-icons/fa6";
import RoutesJSON from '../../json/routes.json';
import {Link} from 'react-router-dom';
import {generateZip} from "../../Tools/ZipGenerator";
import {NavHashLink} from "react-router-hash-link";
import CustomLink from "../CustomLink";
import Register from "../Register";
import {FaYoutube} from "react-icons/fa";

// look at a grid instead of flexbox

const {
    header: {about, contact, rules, games},
    social: {discord, facebook, linkedin, youtube},
    inscription
} = RoutesJSON;
const columns = [
    {
        title: about.name,
        elements: [
            {
                name: 'Informations générales',
                link: about.link,
            },
            {
                name: games.name,
                link: games.link,
            },
        ],
    },
    {
        title: 'Participation',
        elements: [
            {
                name: inscription.name+' (Bientôt disponible)',
                link: inscription.link,
            },
        ],
    },
    {
        title: 'Aide',
        elements: [
            {
                name: contact.name,
                link: contact.link,
            },
            {
                name: rules.name,
                link: rules.link,
            },
        ],
    }
];

const Footer = () => (
    <footer className={'xl:mx-120 mx-16 mb-4 mt-20'}>
        <div className={'flex flex-wrap xl:gap-0 gap-x-4 gap-y-8 justify-between mb-4'}>
            {columns.map((column) => {
                return (
                    <Column>
                        <h3 className={'text-base xl:text-lg'}>{column.title}</h3>
                        {column.elements.map(element =>
                            <CustomLink title>
                                <NavHashLink smooth to={element.link}>{element.name}</NavHashLink>
                            </CustomLink>
                        )}
                    </Column>
                );
            })}
            <Column>
                <h3 className={'text-base xl:text-lg inline'}>Ressources</h3>
                <CustomLink title>
                    <button className={'text-left inline-flex items-center'} onClick={generateZip}>
                        Presskit <FaDownload className={'ml-2'}/>
                    </button>
                </CustomLink>
            </Column>
        </div>
        <hr/>
        <div className={'flex justify-between items-center text-sm my-4 gap-y-3'}>
                <span>© {new Date().getFullYear()} LVLUP Game Jam</span>
            <Social/>
        </div>
        <div className={'text-xs'}>* La plus grande game jam de 48h en présentiel de Suisse.</div>
    </footer>
);

const Column = ({children}) => <div className={'flex flex-col gap-y-2'}>{children}</div>

const Social = () => (
    <div className={'flex gap-2 text-xl'}>
        <Link to={discord.link} target={'_blank'} className={'hover:text-blue'}><FaDiscord/></Link>
        <Link to={youtube.link} target={'_blank'} className={'hover:text-blue'}><FaYoutube/></Link>
        <Link to={facebook.link} target={'_blank'} className={'hover:text-blue'}><FaFacebook/></Link>
        <Link to={linkedin.link} target={'_blank'} className={'hover:text-blue'}><FaLinkedin/></Link>
    </div>
);
export default Footer;
