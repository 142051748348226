import {useState} from "react";
import {FaXmark} from "react-icons/fa6";
import Cookies from "js-cookie";
import editionJSON from "../../json/edition.json"
import routesJSON from "../../json/routes.json"
import CustomButton from "../CustomButton";
import Register, {RegisterButton} from "../Register";

const {date: {startDay, month, year, hour}} = editionJSON;
const {inscription} = routesJSON;

const pad0 = (number) => (number < 10 && number >= 0) ? `0${number}` : number;
export const countdown = () => {
    let result = null;

    const isoDateStr = `20${year}-${month}-${startDay}T${hour}:00:00`;
    const gameJamStartDate = Date.parse(isoDateStr);
    const currentDate = new Date().getTime()

    const difference = gameJamStartDate - currentDate;

    if (difference > 0) {
        result = [];
        const days = pad0(Math.floor(difference / (1000 * 60 * 60 * 24)));
        result.push(days);
        const hours = pad0(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        result.push(hours);
        const minutes = pad0(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)));
        result.push(minutes);
        const seconds = pad0(Math.floor((difference % (1000 * 60)) / 1000));
        result.push(seconds);
    }

    return result;
}

const Banner = () => {
    const [remainingTime, setRemainingTime] = useState(countdown());
    setInterval(() => setRemainingTime(countdown()), 1000)
    const key = "banner";

    const bannerCookie = Cookies.get(key);

    if (bannerCookie === undefined) Cookies.set(key, 'enabled', {expires: 30});  // 30 days expiration

    const [isDisplayed, display] = useState(bannerCookie === 'enabled');

    if (remainingTime != null && isDisplayed) {
        return (
            <div
                className={'flex bg-trueblack text-white py-2 2xl:px-80 px-12 font-bold text-sm justify-between uppercase'}>
                <div></div>
                <div className={'flex items-center text-center justify-center space-x-4'}>
                    <div>
                        <p className={''}>{remainingTime[0]}</p>
                        <p>jours</p>
                    </div>
                    <div>
                        <p className={''}>{remainingTime[1]}</p>
                        <p>heures</p>
                    </div>
                    <div>
                        <p className={''}>{remainingTime[2]}</p>
                        <p>minutes</p>
                    </div>
                    <div>
                        <p className={''}>{remainingTime[3]}</p>
                        <p>secondes</p>
                    </div>
                </div>
                <button className={'hover:text-blue'} onClick={() => {
                    Cookies.set(key, 'disabled', {expires: 30});
                    display(false);
                }}>
                    <FaXmark size={20}/>
                </button>
            </div>
        )
    }
}

export default Banner;