import Section from '../Section';
import {Link} from 'react-router-dom';
import List from "../List";
import CustomLink from "../CustomLink";
import {useState} from "react";
import CustomButton from "../CustomButton";

const Games = ({games}) => {
    const [isGamesHidden, hideGames] = useState([true]);

    const toggleGameVisibility = (index) => {
        hideGames((prevState) => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    return (
        <Section title={'Jeux des éditions précedentes'} id={'jeux'}>
            <List points={[
                <span>
                    Jeux de la {' '}
                    <CustomLink
                        link={'https://itch.io/jam/lvlupgamejam-2024/entries'} external hoverUnderline>dixième édition
                    </CustomLink>
                    <CustomButton textSize={'xs'} onClick={() => {
                        toggleGameVisibility(0)
                    }}>
                        {isGamesHidden[0] ? "Masquer les jeux" : "Afficher les jeux"}
                    </CustomButton>
                </span>
            ]}/>
            <GameGrid games={games[1]} showGames={isGamesHidden[0]}/>
            <List points={[
                <span>
                    Jeux de la {' '}
                    <CustomLink
                        link={'https://itch.io/jam/lvlupgamejam-2023/entries'} external hoverUnderline>neuvième édition
                    </CustomLink>
                    <CustomButton textSize={'xs'} onClick={() => {
                        toggleGameVisibility(1)
                    }}>
                        {isGamesHidden[1] ? "Masquer les jeux" : "Afficher les jeux"}
                    </CustomButton>
                </span>
            ]}/>
            <GameGrid games={games[0]} showGames={isGamesHidden[1]}/>
            <List points={[
                <span> Jeux de la {' '}
                    <CustomLink link={'https://itch.io/jam/lvlupgamejam-2022/entries'} hoverUnderline external>huitème édition</CustomLink>
            </span>,
                <span> Jeux de la {' '}
                    <CustomLink link={'https://itch.io/jam/lvlupgamejam-2021/entries'} hoverUnderline external>septième édition</CustomLink>
            </span>
            ]}/>
        </Section>
    )
}

const Game = ({image, name, link}) => (
    <Link to={link} target={'_blank'} className={'hover:text-orange active:text-orange'}>
        <img className={'rounded-md'} src={image} alt={name}/>
        <h5 className={'text-center xl:text-sm text-xs mt-1'}>{name}</h5>
    </Link>
);

const GameGrid = ({games, showGames}) => (
    <div
        className={`${showGames ? 'block' : 'hidden'} grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-6 my-5`}>
        {games.map((game) => (
            <Game
                image={game.image}
                name={game.name}
                link={game.link}
                className={'col-span-1'}
            />
        ))}
    </div>
);

export default Games;
