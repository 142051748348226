const List = ({points, decimal}) => {
    const listType = decimal ? "list-decimal" : "list-disc"
    // doesn't use <ul> when decimal list
    return (
        <ol className={listType + " list-outside ms-6"}>
            {points.map((point, index) => (
                <li className="my-2" key={index}>
                    {point}
                </li>
            ))}
        </ol>
    );
};

export default List;
