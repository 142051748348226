import editionJSON from '../../json/edition.json';
import {useState} from "react";
import {RegisterButton} from "../Register";

const {startDay, endDay, month, year} = editionJSON.date;
const {edition} = editionJSON;

const Hero = ({sponsors}) => (
    <div className={'flex flex-col'} style={{height: "calc(100vh - 5rem)"}}>
        <div className={'flex flex-grow justify-center text-center items-center bg-trueblack text-white'}>
                <div className={'flex flex-col'}>
                    <p className={'text-4xl font-bold'}>LVLUP {edition}</p>
                    <p className={'text-5xl font-bold'}>{formatDate(startDay, endDay, month, year)}</p>
                    <RegisterButton textSize={'3xl'} text={'INSCRIS-TOI'}/>
                </div>
            </div>
        {sponsors}
    </div>
)

export const Sponsors = ({sponsors}) => (
    <div className={'items-center h-full flex flex-col m-5'}>
        <h3 className={'text-lg xl:text-xl mb-5'}>Soutenu par</h3>
        <div className={'flex-col flex xl:flex-row items-center gap-x-10 gap-y-5'}>
            {sponsors.map((sponsor) => (
                <HoverImage hoverSrc={`${sponsor[0]}.svg`} defaultSrc={`${sponsor[0]}-black.svg`} alt={sponsor[1]}/>
            ))}
        </div>
    </div>
);

const HoverImage = ({hoverSrc, defaultSrc, alt}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <img
            className={'h-12 hover:cursor-pointer'}
            src={isHovered ? hoverSrc : defaultSrc}
            alt={alt}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => window.open(`https://${alt}`, '_blank')}
        />
    );
};

const formatDate = (startDay, endDay, month, year) => `${startDay}-${endDay}.${month.toUpperCase()}.${year}`;

export default Hero;
