import Hero, {Sponsors} from '../../components/Hero';
import About from '../../components/About';
import Games from '../../components/Games';
import GamesJSON from '../../json/games.json';
import Contact from '../../components/Contact';
import Rules from '../../components/Rules';
import {BackToTopButton} from '../../components/CustomButton';
import RoutesJSON from '../../json/routes.json';

const Home = () => {
    const {home} = RoutesJSON;
    const {edition9, edition10} = GamesJSON
    const games = []
    return (
        <div className={home.link}>
            <BackToTopButton/>
            <Hero sponsors={
                <div className={'mx-12 xl:mx-80'}>
                    <Sponsors sponsors={[['images/sponsors/heia', 'heia-fr.ch'], ['images/sponsors/sgc', 'swissgamecenter.ch'], ['images/sponsors/icosys', 'icosys.ch'], ['images/sponsors/liberte', 'laliberte.ch'], ['images/sponsors/gdsc', 'instagram.com/gdsc.fribourg']]}/>
                </div>
            }/>
            <div className={'mx-12 xl:mx-80'}>
                <About/>
            </div>
            <div className={'mx-12 xl:mx-80'}>
                <Games games={[edition9, edition10]}/>
            </div>
            <div className={'mx-12 xl:mx-80'}>
                <Contact/>
            </div>
            <div className={'mx-12 xl:mx-80'}>
                <Rules/>
            </div>
        </div>
    );
}

export default Home;
