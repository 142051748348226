const Section = ({children, title, id}) => (
    <div className={'pt-20 -mt-20'} id={id}> {/* to make the jump to id have an offset*/}
        <div className={'mt-4 mb-8'}>
            <h1 className={'text-2xl xl:text-3xl font-semibold mb-4'}>{title}</h1>
            {children}
        </div>
    </div>
);

export const Subsection = ({children, title}) => (
    <div className={'my-4'}>
        <h2 className={'text-xl xl:text-2xl mb-3'}>{title}</h2>
        {children}
    </div>
);

export default Section;
