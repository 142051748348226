import JSZip from "jszip";
import {saveAs} from 'file-saver';

const zip = new JSZip();

const getFile = async (path) => {
    try {
        const data = await fetch(path);
        return await data.blob();
    } catch (err) {
        console.log(err);
    }
}

const createFolder = (name, files, type) => {
    const folder = zip.folder(name);
    files.forEach((file, index) => folder.file(`${index + 1}.${type}`, file));
}

// consider using an online DB like Firebase or Vercel storage
export const generateZip = async () => {
    try {
        const logosPNG = [];
        const logosSVG = [];
        logosPNG.push(await getFile('./images/logos/lvlup-sm.png'));
        logosSVG.push(await getFile('./images/logos/lvlup-sm.svg'));
        logosPNG.push(await getFile('./images/logos/lvlup-text.png'));
        logosSVG.push(await getFile('./images/logos/lvlup-text.svg'));
        logosPNG.push(await getFile('./images/logos/lvlup-no-text.png'));
        logosSVG.push(await getFile('./images/logos/lvlup-no-text.svg'));
        createFolder('logos-png', logosPNG, 'png');
        createFolder('logos-svg', logosSVG, 'svg');
        const content = await zip.generateAsync({type: 'blob'});
        saveAs(content, "lvlup-game-jam-presskit.zip")
        console.log("Génération du zip terminé.")
    } catch (err) {
        alert("Il y a eu une erreur dans la génération du zip.")
        console.error(err)
    }
}